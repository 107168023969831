// Core
import Layout from "../layout";
import React from "react";

class ContactUs extends React.Component {
  render() {
    return (
      <Layout
        seo={{
          path: "/contact-us",
          title: "Contact Get Anja - New Jersey’s Trusted Cannabis Dispensary",
          description: "Get in touch with ANJA, your trusted Highland Park, NJ dispensary. Whether you have questions about our cannabis products or need assistance, we’re here to help. Contact us today!",
        }}
      >
        <div className="bg-anja-light-purple-overlay-down-90">
          <div className="px-6 pb-8 md:pb-28 relative">
            <div className="cContainer flex items-center flex-col justify-center">
                <div className="text-center w-full pt-16">
                  <h1 className="text-h3 md:text-[60px] lg:text-[70px] tracking-[1px] leading-[123%] md:leading-[119%] font-lato font-black text-white mb-16">Contact</h1>
                  <h6 className="mt-8 text-p font-karla md:text-h5-m leading-[26px] font-normal text-black">As we begin to scale, we would love your thoughts on what you want to see at ANJA.Let's get in touch!</h6>
                </div>
            </div>
          </div>
        </div>
        <div className="pt-6 md:pt-16">
          <div className="px-6">
            <div className="cContainer">
                <div className="text-center">
                  <form 
                  className="mt-4 text-purple" 
                  name="contact"
                  action="/contact-us" 
                  method="POST" 
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  >
                    <div className="grid gird-cols-1 md:grid-cols-2 gap-4 md:gap-8">
                      <input name="form-name" type="hidden" value="contact"/>
                      <div hidden>
                        <label>
                          Don&apos;t fill this part out: <input name="bot-field"/>
                        </label>
                      </div>
                      <label>
                        <input type="text" name="first_name" placeholder="First Name" />
                      </label>
                      <label>
                        <input type="text" name="last_name" placeholder="Last Name" />
                      </label>
                    </div>
                    <label className="mt-4">
                      <input type="email" name="email" placeholder="Email" />
                    </label>
                    <textarea name="message" placeholder="Message" rows="5" className="mt-4">
                    </textarea>
                    <button className="button button--primary mt-8 w-full" type="submit">Submit</button>
                  </form>
                </div>
                <ul className="text-purple h-full font-lato font-extrabold flex justify-between flex-col py-20">
                    <li className="mb-8">
                      <div className="flex flex-col items-center">
                        <a href="mailto:support@getanja.com" className="w-14 h-14">
                          <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.02284 10.9581C6.62927 10.2965 7.6572 10.2518 8.31877 10.8583L26.7207 27.7267L45.1227 10.8583C45.7842 10.2518 46.8121 10.2965 47.4186 10.9581C48.025 11.6197 47.9803 12.6476 47.3188 13.254L27.8188 31.129C27.1975 31.6985 26.2439 31.6985 25.6227 31.129L6.12266 13.254C5.46109 12.6476 5.4164 11.6197 6.02284 10.9581Z" fill="#61149E"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.5957 12.0562C5.5957 11.1587 6.32324 10.4312 7.2207 10.4312H46.2207C47.1182 10.4312 47.8457 11.1587 47.8457 12.0562V39.6812C47.8457 40.5431 47.5033 41.3698 46.8938 41.9793C46.2843 42.5887 45.4577 42.9312 44.5957 42.9312H8.8457C7.98375 42.9312 7.1571 42.5887 6.54761 41.9793C5.93811 41.3698 5.5957 40.5431 5.5957 39.6812V12.0562ZM8.8457 13.6812V39.6812H44.5957V13.6812H8.8457Z" fill="#61149E"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M24.3636 25.5828C24.9702 26.2442 24.9258 27.2722 24.2644 27.8788L8.82686 42.0366C8.16544 42.6432 7.13751 42.5987 6.53091 41.9373C5.92432 41.2759 5.96876 40.248 6.63019 39.6414L22.0677 25.4835C22.7291 24.877 23.757 24.9214 24.3636 25.5828Z" fill="#61149E"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M29.0778 25.5828C29.6844 24.9214 30.7123 24.877 31.3737 25.4835L46.8112 39.6414C47.4727 40.248 47.5171 41.2759 46.9105 41.9373C46.3039 42.5987 45.276 42.6432 44.6146 42.0366L29.1771 27.8788C28.5156 27.2722 28.4712 26.2442 29.0778 25.5828Z" fill="#61149E"/>
                          </svg>
                        </a>
                        <a href="mailto:support@getanja.com" className="md:ml-6 font-lato font-semibold leading-[150%] text-p sm:text-p-xl">support@getanja.com</a>  
                      </div>
                        {/* <span className="w-8 h-8">
                          <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071L1.70711 21.7071C1.31658 22.0976 0.683417 22.0976 0.292893 21.7071C-0.0976311 21.3166 -0.0976311 20.6834 0.292893 20.2929L9.58579 11L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#FFFFFF"/>
                          </svg>
                        </span> */}
                    </li>
                    <li>
                      <div className="flex flex-col items-center">
                        <a href="https://goo.gl/maps/Z1sUb8P1b3EiJpfQ8" target="_blank" rel="noreferrer" className="w-14 h-14">
                          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M24.2207 15.3687C21.7872 15.3687 19.8145 17.3414 19.8145 19.7749C19.8145 22.2084 21.7872 24.1812 24.2207 24.1812C26.6542 24.1812 28.627 22.2084 28.627 19.7749C28.627 17.3414 26.6542 15.3687 24.2207 15.3687ZM16.877 19.7749C16.877 15.7191 20.1649 12.4312 24.2207 12.4312C28.2765 12.4312 31.5645 15.7191 31.5645 19.7749C31.5645 23.8307 28.2765 27.1187 24.2207 27.1187C20.1649 27.1187 16.877 23.8307 16.877 19.7749Z" fill="#61149E"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M24.2207 6.55615C20.7149 6.55615 17.3526 7.94884 14.8736 10.4278C12.3946 12.9068 11.002 16.2691 11.002 19.7749C11.002 25.8554 14.3975 31.3922 17.9853 35.5182C19.7612 37.5605 21.541 39.2072 22.877 40.3428C23.4054 40.7919 23.8627 41.1597 24.2207 41.439C24.5787 41.1597 25.036 40.7919 25.5644 40.3428C26.9004 39.2072 28.6802 37.5605 30.4561 35.5182C34.0439 31.3922 37.4395 25.8554 37.4395 19.7749C37.4395 16.2691 36.0468 12.9068 33.5678 10.4278C31.0888 7.94884 27.7265 6.55615 24.2207 6.55615ZM24.2207 43.2749C23.3784 44.4781 23.378 44.4779 23.3776 44.4775L23.3763 44.4767L23.3729 44.4743L23.362 44.4666L23.3247 44.44C23.293 44.4174 23.2477 44.3848 23.1898 44.3425C23.074 44.258 22.9075 44.1346 22.6977 43.9743C22.2782 43.6537 21.6845 43.1845 20.9745 42.581C19.5567 41.3758 17.6645 39.626 15.7686 37.4457C12.0127 33.1264 8.06445 26.9131 8.06445 19.7749C8.06445 15.49 9.76662 11.3806 12.7965 8.35071C15.8264 5.32082 19.9358 3.61865 24.2207 3.61865C28.5056 3.61865 32.615 5.32082 35.6449 8.35071C38.6748 11.3806 40.377 15.49 40.377 19.7749C40.377 26.9131 36.4287 33.1264 32.6728 37.4457C30.7769 39.626 28.8847 41.3758 27.4669 42.581C26.7569 43.1845 26.1632 43.6537 25.7437 43.9743C25.5339 44.1346 25.3675 44.258 25.2516 44.3425C25.1937 44.3848 25.1485 44.4174 25.1167 44.44L25.0794 44.4666L25.0685 44.4743L25.0651 44.4767L25.0639 44.4775C25.0634 44.4779 25.063 44.4781 24.2207 43.2749ZM24.2207 43.2749L25.0639 44.4775C24.5581 44.8315 23.8833 44.8315 23.3776 44.4775L24.2207 43.2749Z" fill="#61149E"/>
                          </svg>
                        </a>
                        <a href="https://goo.gl/maps/Z1sUb8P1b3EiJpfQ8" target="_blank" rel="noreferrer" className="md:ml-6 font-lato font-semibold leading-[150%] text-p sm:text-p-xl">225 Woodbridge Ave.</a>
                      </div>
                      {/* <span className="w-8 h-8">
                        <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd" d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071L1.70711 21.7071C1.31658 22.0976 0.683417 22.0976 0.292893 21.7071C-0.0976311 21.3166 -0.0976311 20.6834 0.292893 20.2929L9.58579 11L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z" fill="#61149E"/>
                        </svg>
                      </span> */}
                    </li>
                  </ul>

            </div>
          </div>
          
        </div>
      </Layout>
    );
  }
}

export default ContactUs;